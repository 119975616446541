/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// CSS
import './Footer.scss'

// Images
import footerLogo from 'img/footer_logo.svg'
import facebook from 'img/facebook.svg'
import linkedin from 'img/linkedin.svg'
import twitter from 'img/twitter.svg'
import instagram from 'img/instagram.svg'
import mapPin from 'img/map_pin.svg'
import phone from 'img/phone.svg'
import email from 'img/email.svg'

// Components
import CustomLink from 'components/CustomLink'
import GravityForm from 'components/GravityForm'

// Third Party
import _ from 'lodash'

const Footer = () => {
  const {
    contact: {
      acf: {
        common_info: {
          btw_number: btwNumber,
          kvk_number: kvkNumber,
          common_address: commonAddress,
          common_city: commonCity,
          company_mail: companyMail,
          company_name: companyName,
          phonenumber,
        },
        social: {
          facebook_link: facebookLink,
          linkedin_link: linkedinLink,
          twitter_link: twitterLink,
          instagram_link: instagramLink,
        },
      },
    },
    footer: {
      acf: { footer_title: footerTitle, about_us: aboutUs },
    },
    soorten: { edges: soortList },
  } = useStaticQuery(graphql`
    {
      contact: wordpressPage(wordpress_id: { eq: 17 }) {
        acf {
          common_info {
            btw_number
            common_address
            common_city
            company_mail
            company_name
            kvk_number
            phonenumber
          }
          social {
            facebook_link
            linkedin_link
            twitter_link
            instagram_link
          }
        }
      }
      footer: wordpressPage(wordpress_id: { eq: 87 }) {
        acf {
          footer_title
          about_us
        }
      }
      soorten: allWordpressWpSoorten(sort: { fields: acf___sorteernummer }) {
        edges {
          node {
            slug
            name
          }
        }
      }
    }
  `)

  return (
    <>
      <div className="footer color-background-secondary">
        <div className="container">
          <div className="footer-title-row pb-3 px-3 pb-lg-5 row">
            <img alt="" src={footerLogo} />
            <h2 className="font-size-xl text-center text-lg-left font-weight-xl color-text-light d-flex align-items-center">
              {footerTitle}
            </h2>
          </div>
          <div className="footer-content-row row pb-5">
            <div className="col-12 col-lg-3">
              <div className="footer-column-title pb-4">
                <div className="title-wrapper">
                  <h2 className="font-size-xm font-weight-xl">
                    Onze Producten
                  </h2>
                </div>
              </div>
              <div className="footer-column-content pr-4">
                {_.map(soortList, (soort, index) => (
                  <CustomLink
                    key={index}
                    to={`/producten/${soort.node.slug}/${soort.node.slug}/#a`}
                  >
                    <div className="footer-column-content-link">
                      <b className="color-text-light">&gt;</b> {soort.node.name}
                    </div>
                  </CustomLink>
                ))}
              </div>
            </div>
            <div className="col-12 col-lg-5 pt-5 pt-lg-0 footer-column">
              <div className="footer-column-title pb-4">
                <div className="title-wrapper">
                  <h2 className="font-size-xm font-weight-xl">Over ons</h2>
                </div>
              </div>
              <div className="footer-column-content pr-4">
                <div dangerouslySetInnerHTML={{ __html: aboutUs }} />
                <div className="socials-row pt-4">
                  <a href={facebookLink}>
                    <img
                      className="mr-4 social-footer-image"
                      alt=""
                      src={facebook}
                    />
                  </a>
                  <a href={linkedinLink}>
                    <img
                      className="mr-4 social-footer-image"
                      alt=""
                      src={linkedin}
                    />
                  </a>
                  <a href={twitterLink}>
                    <img
                      className="mr-4 social-footer-image"
                      alt=""
                      src={twitter}
                    />
                  </a>
                  <a href={instagramLink}>
                    <img
                      className="mr-4 social-footer-image"
                      alt=""
                      src={instagram}
                    />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-4 pt-5 pt-lg-0">
              <div className="footer-column-title pb-4">
                <div className="title-wrapper">
                  <h2 className="font-size-xm font-weight-xl">Contact</h2>
                </div>
              </div>
              <div className="footer-column-content font-size-m">
                <div className="d-flex mb-3 justify-content-between">
                  <div className="footer-column-content-image-container">
                    <img className="align-self-start" alt="" src={mapPin} />
                  </div>
                  <div className="footer-column-content-text-container">
                    <p className="m-0">{companyName}</p>
                    <p className="m-0">{commonAddress}</p>
                    <p className="m-0">{commonCity}</p>
                  </div>
                </div>
                <div className="d-flex align-content-center justify-content-between">
                  <div className="footer-column-content-image-container">
                    <img alt="" src={phone} />
                  </div>
                  <div className="footer-column-content-text-container">
                    <a href={`tel:${phonenumber}`} className="m-0">
                      {phonenumber}
                    </a>
                  </div>
                </div>
                <div className="d-flex mb-3 align-content-center justify-content-between">
                  <div className="footer-column-content-image-container">
                    <img alt="" src={email} />
                  </div>
                  <div className="footer-column-content-text-container">
                    <a href={`mailto:${companyMail}`} className="m-0">
                      {companyMail}
                    </a>
                  </div>
                </div>
                <p className="m-0">{`KvK-nummer: ${kvkNumber}`}</p>
                <p className="m-0">{`BTW-nummer: ${btwNumber}`}</p>
                <div>
                  <h2 className="font-size-m font-weight-xl pt-3 pb-2">
                    Schrijf u in voor onze nieuwsbrief
                  </h2>
                  <div className="separator d-lg-none d-block" />
                  <GravityForm nieuwsbrief id={2} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-color-line color-background-contrast" />
    </>
  )
}

export default Footer
