/* eslint-disable react/no-array-index-key */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState } from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { CSSTransition } from 'react-transition-group'
import { motion } from "framer-motion";

// Images
import imgLogo from 'img/logo.svg'
import menu from 'img/menu.svg'
import close from 'img/close.svg'
import chevronDown from 'img/chevron_down.svg'

// Components
import CustomLink from 'components/CustomLink'
import { ButtonLineAlt, ButtonAlt } from 'components/Elements/Elements'

// CSS
import './Header.scss'

// Third Party
import _ from 'lodash'

const Header = ({ noPhone }) => {
  const [isCollapsed, setCollapsed] = useState(true);

  const {
    wordpressMenusMenusItems: {
      items: menuItems
    },
    wordpressPage: {
      acf: {
        common_info: {
          phonenumber
        }
      }
    },
    products: {
      edges: products
    }
  } = useStaticQuery(graphql`
  query headerQuery {
    wordpressMenusMenusItems(wordpress_id: {eq: 10}) {
      items {
        title
        slug
        target
        classes
        childItems: child_items {
          title
          url
          target
          slug
        }
      }
    }
    wordpressPage(slug: {eq: "contact"}) {
      acf {
        common_info {
          phonenumber
        }
      }
    }
    products: allWordpressWpSoorten {
      edges {
        node {
          name
          slug
        }
      }
    }
  }
  `)

  const filteredMenuItems = _.filter(menuItems, ({ slug }) => slug !== 'home' && slug !== 'null')

  return (
    <nav className="header pt-2 pb-4 position-relative">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-8 col-lg-2">
            <div className="navbar-brand">
              <Link to="/" className="navbar-item">
                <img className="header-img-logo" src={imgLogo} alt="" />
              </Link>
            </div>
          </div>
          <div className="col-4 col-lg-10 navbar-container">
            <div className="row w-100">
              <div className="top-header col-12 d-none d-lg-block">
                <div className="container">
                  <div className="row h-100 align-items-center justify-content-end">
                    <ButtonLineAlt isAnchor to="/download-inspiratiemagazine" className="download color-background-main color-text-light font-size-sm font-weight-xl ml-3 px-2 py-1 uppercase">
                      Download inspiratiemagazine
                    </ButtonLineAlt>
                  </div>
                </div>
              </div>
              <div className="col-12 d-flex">
                <button type="button" onClick={() => setCollapsed(!isCollapsed)} className={`navbar-collapse-button${isCollapsed ? ' navbar-collapse-button-collapsed' : ''} text-center d-block d-lg-none`}>
                  {isCollapsed ? (
                    <>
                      <img alt="menu" src={menu} />
                    </>
                  ) : (
                    <img alt="close" src={close} />
                  )}
                </button>

                <HeaderMenuMobile products={products} phone={phonenumber} filteredMenuItems={filteredMenuItems} isCollapsed={isCollapsed} setCollapse={setCollapsed} />
                <HeaderMenuDesktop phone={phonenumber} filteredMenuItems={filteredMenuItems} />

                <ButtonLineAlt isAnchor to="/showroomafspraak-maken" className="showroomafspraak header-phonenumber color-background-contrast color-text-light font-size-sm font-weight-xl ml-3 px-3 py-2 uppercase">
                  Maak een afspraak
                </ButtonLineAlt>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!noPhone && (
        <div className="container d-flex justify-content-center">
          <ButtonAlt isAnchor to={`tel:${phonenumber}`} className="position-absolute mobile-phone-cta d-flex d-lg-none px-3 justify-content-center font-size-sm font-weight-xl py-1 uppercase">
            {phonenumber.replace('-', ' - ')}
          </ButtonAlt>
        </div>
      )}
    </nav>
  )
}

const HeaderMenuMobile = ({ products, phone, filteredMenuItems, isCollapsed }) => (
  <CSSTransition in={!isCollapsed} timeout={300} unmountOnExit classNames="header-menu">
    <div className="navbar-menu">
      <ul className="navbar-items">
        <HeaderMenuItems products={products} mobile phone={phone} filteredMenuItems={filteredMenuItems} />
      </ul>
    </div>
  </CSSTransition>
)

const HeaderMenuDesktop = ({ phone, filteredMenuItems }) => (
  <ul className='navbar-items d-none d-lg-flex'>
    <HeaderMenuItems phone={phone} mobile={false} filteredMenuItems={filteredMenuItems} />
  </ul>
)

const HeaderMenuItems = ({ products, mobile, phone, filteredMenuItems }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const variants = {
    open: { opacity: 1, position: 'relative', visibility: 'visible' },
    closed: { opacity: 0, position: 'absolute', visibility: 'hidden' },
  };

  return (
    <>
      {mobile ? (
        <>
          {filteredMenuItems.map(({ title, slug, childItems, target }) => (
            <li key={slug}>
              {slug === 'producten' ? (
                <motion.div
                  className="menu-item"
                >
                  <p className="font-size-sm mb-0 uppercase" onClick={() => setIsOpen(isOpen => !isOpen)}>
                    {title}
                    {' '}
                    <img className="sub-item-chevron" alt="" src={chevronDown} />
                  </p>
                  <motion.div
                    className="sub-menu"
                    animate={isOpen ? "open" : "closed"}
                    variants={variants}
                  >
                    {products.map((product, index) => (
                      <div className="mb-1">
                        <CustomLink className="sub-item font-size-sm uppercase color-text-main" key={index} to={`/producten/${product.node.slug}`}>
                          {product.node.name}
                        </CustomLink>
                      </div>
                    ))}
                  </motion.div>
                </motion.div>
              ) : (
                <>
                  {target === "_blank" ? (
                    <a href={`/${slug}`} className="font-size-sm uppercase" target={target || '_self'}>
                      {title}
                    </a>
                  ) : (
                    <CustomLink to={`/${slug}`} className="font-size-sm uppercase" target={target || '_self'}>
                      {title}
                    </CustomLink>
                  )}
                </>
              )}
            </li>
          ))}
          <a href="/showroomafspraak-maken">
            <p className="mobile-menu-phone-cta color-background-contrast font-size-xm font-weight-xl color-text-light px-5 py-3 text-uppercase">Maak een afspraak</p>
          </a>
        </>
      ) : (
        <>
          {filteredMenuItems.map(({ title, slug, classes, childItems, target }) => (
            <li key={slug} className={`${classes} header-a${childItems !== null ? ' has-children' : ''}`}>
              {target === "_blank" ? (
                <a href={`/${slug}`} rel="noreferrer" className="font-size-sm uppercase" target="_blank">
                  {title}
                </a>
              ) : (
                <CustomLink to={`/${slug}`} className="font-size-sm uppercase">
                  {title}
                </CustomLink>
              )}

              {childItems !== null && (
                <>
                  <ul className="sub-menu">
                    {childItems.map((child_item, key) => (
                      <li key={key} className="sub-menu-item">
                        <CustomLink to={child_item.url} className="font-size-sm uppercase">
                          <span
                            dangerouslySetInnerHTML={{ __html: child_item.title }}
                          />
                        </CustomLink>
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </li>
          ))}
        </>
      )}
    </>
  )
}

export default Header
