/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import CustomLink from 'components/CustomLink'

// CSS
import './Elements.scss'

// Images
import whiteArrow from 'img/white_arrow.svg'
import orangeArrow from 'img/orange_arrow.svg'

// Third Party
import _ from 'lodash'

export const ButtonDefault = ({ isAnchor, to, children, className }) => {
  const classes = `button button-default${className ? ` ${className}` : ``}`

  return (
    <ButtonShell to={to} isAnchor={isAnchor} className={classes}>
      {children}
    </ButtonShell>
  )
}

export const ButtonAlt = ({ isAnchor, to, children, className }) => {
  const classes = `button button-alt${className ? ` ${className}` : ``}`

  return (
    <ButtonShell to={to} isAnchor={isAnchor} className={classes}>
      {children}
    </ButtonShell>
  )
}

// isAnchor: true/false | false for inside gatsby link, true for outside gatsby link
// to: string | link of path to destination
// children: inner elements of the components | <div>text<div>
// className: string | classnames
export const ButtonLineDefault = ({ isAnchor, to, children, className }) => {
  const classes = `button button-line-default${className ? ` ${className}` : ``}`

  return (
    <ButtonShell to={to} isAnchor={isAnchor} className={classes}>
      {children}
    </ButtonShell>
  )
}

export const ButtonLineRound = ({ isAnchor, to, children, className }) => {
  const classes = `button button-line-round${className ? ` ${className}` : ``}`

  return <ButtonShell to={to} isAnchor={isAnchor} className={classes}>{children}</ButtonShell>
}

export const ButtonLineAlt = ({ isAnchor, to, children, className }) => {
  const classes = `button button-line-alt${className ? ` ${className}` : ``}`

  return <ButtonShell to={to} isAnchor={isAnchor} className={classes}>{children}</ButtonShell>
}

const ButtonShell = ({ isAnchor, to, children, className }) => (
  !isAnchor ? (
    <CustomLink to={to} className={className}>{children}</CustomLink>
  ) : (
    <a href={to} className={className}>{children}</a>
  )
)

export const TitleDefault = ({ children, className }) => (
  <h2 className={`title title-default${className ? ` ${className}` : ``}`}>{children}</h2>
)

export const TitleAlt = ({ children, className }) => (
  <h2 className={`title title-alt${className ? ` ${className}` : ``}`}>{children}</h2>
)

export const TitleSubDefault = ({ children, className }) => (
  <div className={`title title-sub-default${className ? ` ${className}` : ``}`}>{children}</div>
)

export const BoxDefault = ({ children, className }) => (
  <div className={`box${className ? ` ${className}` : ``}`}>{children}</div>
)

export const ContentDefault = ({ children, className, html }) => (
  <div className={`content${className ? ` ${className}` : ``}`} dangerouslySetInnerHTML={{ __html: html }}>
    {children}
  </div>
)

export const BannerDefault = ({ children, className }) => (
  <div className={`banner${className ? ` ${className}` : ``}`}>
    <div className="container">
      {children}
    </div>
  </div>
)

export const BannerCustom = ({ to, bannerText, bannerButtonText, className}) => (
  <BannerDefault className={`py-3 py-lg-5 ${className ? `${className}` : ``}`}>
    <div className="banner-custom-container container d-flex">
      <div className="banner-custom-text text-center d-flex justify-content-center">
        <h2 className="font-weight-xl font-size-xl">{bannerText}</h2>
      </div>
      <div className="banner-custom-button d-flex justify-content-center">
        <CustomLink className="px-3 py-2 color-background-contrast d-flex align-items-center ctabanner-button" to={to}>
          <p className="color-text-light font-weight-xl mb-0 text-uppercase">{bannerButtonText}</p>
          <img alt="arrow" src={whiteArrow} />
        </CustomLink>
      </div>
    </div>
  </BannerDefault>
)

export const BannerWithLine = ({ bannerText, left, className}) => (
  <BannerDefault className={`py-4 ${className ? `${className}` : ``}`}>
    <div className="d-flex justify-content-evenly">
      {left ? (
        <>
          <p className="w-40 left banner-line color-background-contrast" />
          <h2 className="font-weight-xl font-size-xl">{bannerText}</h2>
        </>
      ) : (
        <>
          <h2 className="font-weight-xl font-size-xl">{bannerText}</h2>
          <p className="w-100 right banner-line color-background-contrast" />
        </>
      )}
    </div>
  </BannerDefault>
)

export const ProductBanner = ({ slug, producten, className}) => (
  <BannerDefault className={`py-4 ${className ? `${className}` : ``}`}>
    <div className="row justify-content-center">
      {_.map(producten.reverse(), o => (
        <div className={`col-12 col-sm-6 ${producten.length >= 7 ? `my-2` : `my-2 my-lg-0`}  col-md-4 col-lg-2 px-3 d-flex justify-content-center`}>
          <ButtonLineRound to={`/producten/${slug}/${o.post_name}/#a`} className="h-100 w-100 color-background-light">
            <p className="d-flex text-center justify-content-center m-0" dangerouslySetInnerHTML={{ __html: o.post_title}} />
            <div className="button-line-round-image-container"><img alt="" src={orangeArrow} /></div>
          </ButtonLineRound>
        </div>
      ))}
    </div>
  </BannerDefault>
)