import React from 'react'
import CookieConsent from 'react-cookie-consent'

// Components
import Header from './Header'
import Footer from './Footer'

// CSS
import 'styles/all.scss'

const Layout = ({ children, noPhone }) => (
  <>
    <Header noPhone={noPhone} />
    <>{children}</>
    <Footer />
   
  </>
)

export default Layout
