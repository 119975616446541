/* eslint-disable no-console */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect } from 'react'
import { navigate, useStaticQuery, graphql } from 'gatsby'
import { v4 } from 'uuid'

// Components
import Content from 'components/Content'

// CSS
import './GravityForm.scss'

// Third Party
import _ from 'lodash'
import Select from 'react-select'
import Radio from '@mui/material/Radio'

// Utilities
import { chunkArray, calculateColumn } from '../../utils'

const GravityForm = ({ nieuwsbrief, bedankt, id, perColumn }) => {
  const {
    allGfForm: { edges: gravityData },
  } = useStaticQuery(graphql`
    query {
      allGfForm {
        edges {
          node {
            formId
            slug
            apiURL
            descriptionPlacement
            formFields {
              id
              label
              labelPlacement
              description
              descriptionPlacement
              type
              choices
              content
              errorMessage
              inputMaskValue
              isRequired
              visibility
              cssClass
              placeholder
              size
              defaultValue
              maxLength
              conditionalLogic
            }
            button {
              text
            }
            confirmations {
              message
            }
          }
        }
      }
    }
  `)

  const { node: gfForm } = gravityData.filter(
    ({ node }) => node.formId === id
  )[0]

  const [fields, setFields] = useState({})
  const [form, setForm] = useState(gfForm)
  const [status, setStatus] = useState('')
  const [message, setMessage] = useState('')

  useEffect(() => {
    let tempForm = form

    // add submit button as a field
    if (
      tempForm.formFields.filter((item) => item.type === 'submit').length === 0
    ) {
      tempForm.formFields = [
        ...tempForm.formFields,
        {
          formId: v4(),
          type: 'submit',
          text: tempForm.button.text,
        },
      ]
    }

    if (perColumn) {
      tempForm = {
        ...tempForm,
        formFields: chunkArray(tempForm.formFields, perColumn),
      }
    }

    setForm({
      ...form,
      ...tempForm,
    })
  }, [])

  async function handleOnSubmit(event) {
    event.preventDefault()

    if (status === 'processing') {
      return
    }

    setStatus('processing')

    try {
      const formData = new FormData()

      for (const [key, value] of Object.entries(fields)) {
        formData.append(key, value)
      }

      const request = await fetch(`${form.apiURL}/submissions`, {
        method: 'POST',
        body: formData,
      })

      const response = await request.json()
      if (response.is_valid === true) {
        setStatus('done')

        setMessage(response.confirmation_message)

        // if (!nieuwsbrief) {
        //   navigate("/bedankt-contact");
        // } else {
        //   navigate("/bedankt-nieuwsbrief");
        // }
      } else {
        setStatus('error')
        console.log(response)
      }
    } catch (error) {
      setStatus('error')
      console.error(error)
    }
  }

  function handleFieldChange(event) {
    // eslint-disable-next-line prefer-destructuring

    let { value } = event.target || event.value

    if (event.target) {
      if (event.target.type === 'checkbox') {
        value = event.target.checked ? event.target.value : ''
      }

      setFields({
        ...fields,
        [event.target.name]: value,
      })
    } else {
      setFields({
        ...fields,
        [event.name]: event.value,
      })
    }
  }

  if (status === 'done') {
    return <Content className="form-submission-message" content={message} />
  }

  if (form.formFields) {
    return (
      <form
        method="post"
        id={`form_${form.formId}`}
        className={`form form_${form.formId}`}
        onSubmit={handleOnSubmit}
      >
        {/* {bedankt ? (
          <>
            <p className="form-submission-message">
Bedankt voor uw bericht, wij zullen binnenkort contact met u opnemen.
Indien u een offerte heeft aangevraagd, kan dit in verband met de seizoensdrukte enige dagen duren.
            </p>
          </>
        ) : ( */}
        <>
          {status === 'processing' && <Loading />}
          <div className="row">
            {form.formFields &&
              form.formFields.map((field, key) => {
                if (Array.isArray(field)) {
                  return (
                    <div
                      key={key}
                      className={`col-lg-${calculateColumn(
                        form.formFields.length
                      )}`}
                    >
                      {field.map((item, index) => (
                        <FormField
                          key={index}
                          field={item}
                          fields={fields}
                          onChange={handleFieldChange}
                        />
                      ))}
                    </div>
                  )
                }

                return (
                  <div className="col-lg-12">
                    <FormField
                      nieuwsbrief={nieuwsbrief}
                      key={key}
                      field={field}
                      fields={fields}
                      onChange={handleFieldChange}
                    />
                  </div>
                )
              })}
          </div>
        </>
        {/* )} */}
      </form>
    )
  }

  console.error('No gravity forms found with id', id)
  return false
}

const TextField = ({
  value,
  onChange,
  field: {
    id,
    type,
    label,
    labelPlacement,
    placeholder,
    isRequired,
    cssClass,
    conditionalLogic,
  },
}) => (
  <div className="form-group">
    {labelPlacement !== 'hidden_label' && (
      <label htmlFor={`input_${id}`}>
        {label}
        {isRequired && <span> *</span>}
      </label>
    )}
    <input
      value={value}
      onChange={onChange}
      type="text"
      id={`input_${id}`}
      className={`${type} ${cssClass !== undefined ? cssClass : ''}`}
      name={`input_${id}`}
      required={isRequired}
      placeholder={placeholder}
    />
  </div>
)

const EmailField = ({
  value,
  onChange,
  field: { id, type, label, labelPlacement, placeholder, isRequired, cssClass },
}) => (
  <div className="form-group">
    {labelPlacement !== 'hidden_label' && (
      <label htmlFor={`input_${id}`}>
        {label}
        {isRequired && <span> *</span>}
      </label>
    )}
    <input
      value={value}
      onChange={onChange}
      type="email"
      id={`input_${id}`}
      className={`${type} ${cssClass !== undefined ? cssClass : ''}`}
      name={`input_${id}`}
      required={isRequired}
      placeholder={placeholder}
    />
  </div>
)

const PhoneField = ({
  value,
  onChange,
  field: { id, type, label, labelPlacement, placeholder, isRequired, cssClass },
}) => (
  <div className="form-group">
    {labelPlacement !== 'hidden_label' && (
      <label htmlFor={`input_${id}`}>{label}</label>
    )}
    <input
      value={value}
      onChange={onChange}
      type="tel"
      id={`input_${id}`}
      className={`${type} ${cssClass !== undefined ? cssClass : ''}`}
      name={`input_${id}`}
      required={isRequired}
      placeholder={placeholder}
    />
  </div>
)

const TextAreaField = ({
  value,
  onChange,
  field: { id, type, label, labelPlacement, placeholder, isRequired, cssClass },
}) => (
  <div className="form-group">
    {labelPlacement !== 'hidden_label' && (
      <label htmlFor={`input_${id}`}>{label}</label>
    )}
    <textarea
      value={value}
      onChange={onChange}
      id={`input_${id}`}
      className={`${type} ${cssClass !== undefined ? cssClass : ''}`}
      name={`input_${id}`}
      required={isRequired}
      placeholder={placeholder}
    />
  </div>
)

const CheckboxField = ({
  value,
  onChange,
  field: { id, type, label, labelPlacement, cssClass, choices },
}) => {
  const list = JSON.parse(choices)

  return (
    <div className="form-group">
      {list.map((checkbox, key) => (
        <div key={key} className="form-group__checkboxes">
          <input
            checked={value}
            onChange={onChange}
            type="checkbox"
            id={`input_${id}_${key + 1}`}
            className={`${type} ${cssClass !== undefined ? cssClass : ''}`}
            name={`input_${id}_${key + 1}`}
            value={checkbox.value}
          />
          <label
            htmlFor={`input_${id}_${key + 1}`}
            className="checkbox-content"
          >
            {checkbox.text}
          </label>
        </div>
      ))}
    </div>
  )
}

// Select or Dropdown
const SelectField = ({ field, onChange, value }) => {
  // Populate a options array
  const options = []
  _.each(JSON.parse(field.choices), (choice) => {
    options.push({
      value: choice.value,
      label: choice.text,
      type: 'select',
      name: `input_${field.id}`,
    })
  })

  // Custom Select component
  const MySelect = (props) => (
    <Select
      {...props}
      onChange={props.onChange}
      options={props.options}
      placeholder={props.placeholder}
    />
  )

  return (
    <div className="form-select">
      <MySelect
        options={options}
        onChange={onChange}
        value={options.filter((option) => option.value === value)}
        placeholder={field.label}
        classNamePrefix="react-select"
        className="react-select-container"
      />
    </div>
  )
}

const HTMLField = ({ field: { content, conditionalLogic } }) => (
  <div className="html-field">
    <Content content={content} />
  </div>
)

const SubmitButton = ({ nieuwsbrief, field }) => (
  <div className={`form-footer ${nieuwsbrief ? `mt-0` : `mt-5`}`}>
    <button className="button form-submit" type="submit">
      {field.text}
    </button>
  </div>
)

export const FormField = ({ nieuwsbrief, field, fields, onChange }) => (
  <>
    {field.type === 'text' && (
      <TextField
        onChange={onChange}
        value={fields[`input_${field.id}`]}
        field={field}
      />
    )}
    {field.type === 'email' && (
      <EmailField
        onChange={onChange}
        value={fields[`input_${field.id}`]}
        field={field}
      />
    )}
    {field.type === 'phone' && (
      <PhoneField
        onChange={onChange}
        value={fields[`input_${field.id}`]}
        field={field}
      />
    )}
    {field.type === 'textarea' && (
      <TextAreaField
        onChange={onChange}
        value={fields[`input_${field.id}`]}
        field={field}
      />
    )}
    {field.type === 'checkbox' && (
      <CheckboxField
        onChange={onChange}
        value={fields[`input_${field.id}`]}
        field={field}
      />
    )}
    {field.type === 'select' && (
      <SelectField
        onChange={onChange}
        value={fields[`input_${field.id}`]}
        field={field}
      />
    )}
    {field.type === 'html' && <HTMLField field={field} />}
    {field.type === 'submit' && (
      <SubmitButton nieuwsbrief={nieuwsbrief} field={field} />
    )}
  </>
)

export const Loading = () => (
  <div className="form-loading">
    <div className="form-loading-spinner">
      <div className="sk-chase">
        <div className="sk-chase-dot" />
        <div className="sk-chase-dot" />
        <div className="sk-chase-dot" />
        <div className="sk-chase-dot" />
        <div className="sk-chase-dot" />
        <div className="sk-chase-dot" />
      </div>
    </div>
  </div>
)

export default GravityForm
